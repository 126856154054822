
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { mask } from "vue-the-mask";
import { useI18n } from "vue-i18n";
import { CountryListModel } from "@/domain/country/countryList/model/CountryListModel";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { AddStaffModel } from "@/domain/staff/addStaff/model/AddStaffModel";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "add-staff",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const store = useStore();
    const swalNotification = new SwalNotification();
    const submitButton = ref<HTMLElement | null>(null);
      const addStaffModel = ref<AddStaffModel>({
        countryCode: 397,
      });

    const countryList = ref<CountryListModel[]>([]);
    const countryController = store.state.ControllersModule.countryController;
    const staffController = store.state.ControllersModule.staffController;

    //Create form validation object
    const addStaffForm = Yup.object().shape({
      name: Yup.string().required(t("validators_error.required")).label("Name"),
      surname: Yup.string()
        .required(t("validators_error.required"))
        .label("Surname"),
      email: Yup.string()
        .required(t("validators_error.required"))
        .email(t("validators_error.email"))
        .label("Email"),
        phone: Yup.string()
        .required(t("validators_error.required"))
        .label("Phone")
        .min(15, t("validators_error.phoneNumberFull")),
      department: Yup.string()
        .required(t("validators_error.required"))
        .label("Department"),
      jobPosition: Yup.string()
        .required(t("validators_error.required"))
        .label("JobPosition"),
    });

    const getCountryList = async () => {
      const countryListModel: CountryListModel = {
        country: {},
        page: 1,
        pageSize: 50,
      };

      countryController
        .countryList(countryListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((country) => {
              countryList.value.push(country);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

        const onSubmit = (values) => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      addStaffModel.value.phoneNumber = addStaffModel.value.phoneNumber
        ?.toString()
        .replace(/\D/g, "");

        console.log(addStaffModel.value)

      staffController
        .addStaff(addStaffModel.value)
        .then((response) => {
          if (response.isSuccess) {
            submitButton.value?.removeAttribute("data-kt-indicator");

            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_ADD_STAFF),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("common.button.ok"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(() => {
              router.push({ path: `/staffList` });
              });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      setCurrentPageTitle(t("ogza.staffList.addStaff.title"));
      getCountryList();
    });

    return {
      addStaffModel,
      addStaffForm,
      countryList,
      onSubmit,
    };
  },
  directives: { mask },
});
